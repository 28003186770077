const reports = [
  {
    path: '/reports/view/producao',
    name: 'reports.production',
    component: () => import('@/views/reports/ProductionReport.vue'),
  },
  {
    path: '/reports/view/oee',
    name: 'reports.oee',
    component: () => import('@/views/reports/ProductionReport.vue'),
  },
  {
    path: '/reports/view/avanco',
    name: 'reports.avanco',
    component: () => import('@/views/reports/ProductionReport.vue'),
  },
]

export default reports
